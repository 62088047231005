import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { throttle } from 'throttle-debounce';

export function init() {
	const headerBurgerElement = document.getElementById('header-burger');
	const panelElement = document.getElementById('panel');
	const logoBgElement = document.getElementById('header-logo-bg');
	const headerAdditionalElement = document.getElementById(
		'header-additional'
	);
	let headerTimerId = null;

	headerBurgerElement.addEventListener(
		'click',
		() => {
			if (headerBurgerElement.classList.contains('is-active')) {
				closeMenu();
			} else {
				openMenu();
			}
		},
		false
	);

	function openMenu() {
		disableBodyScroll();
		headerBurgerElement.classList.add('is-active');
		panelElement.classList.add('panel--active');
		logoBgElement.classList.add('header__logo-bg--active');
		if (headerAdditionalElement) {
			headerAdditionalElement.classList.add(
				'page__header-additional--hidden'
			);
			const duration = getTransitionDuration(headerAdditionalElement);
			clearTimeout(headerTimerId);
			headerTimerId = setTimeout(() => {
				headerAdditionalElement.style.display = 'none';
			}, duration);
		}
	}

	function closeMenu() {
		enableBodyScroll();
		headerBurgerElement.classList.remove('is-active');
		panelElement.classList.remove('panel--active');
		logoBgElement.classList.remove('header__logo-bg--active');
		if (headerAdditionalElement) {
			clearTimeout(headerTimerId);
			headerAdditionalElement.style.display = null;
			setTimeout(() => {
				headerAdditionalElement.classList.remove(
					'page__header-additional--hidden'
				);
			}, 0);
		}
	}

	function getTransitionDuration(el) {
		return (
			parseFloat(getComputedStyle(el, null).transitionDuration, 10) * 1000
		);
	}

	window.addEventListener(
		'resize',
		throttle(300, () => {
			if (window.innerWidth >= 768) {
				closeMenu();
			}
		})
	);
}
